<script>
    import { version } from '../store.js'
    export let showHelp
    import { fly } from 'svelte/transition';
    // import X from '$assets/x.svg'

    function closeHelp() {
        showHelp = false
    }


    const lsname = 'crossword'
  let months = { 
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  }
  let promise = false
  import { ChevronLeft, Check, X } from 'lucide-svelte';
    import { browser } from '$app/environment'
    export let data

    let statistics = []
 
    
    let solvedCount = 0
 

    
    
</script>

{#if showHelp}
<div class="statistics" transition:fly="{{ y: 50, duration: 500 }}">
    <div class="statistics-inner">
        <div class="close-button" on:click={closeHelp}><X /></div>

        <h2>How to play this game</h2>
        <p>You have 3 chances to solve the cryptic clue. There are two hints available (click the lightbulbs below). </p>
        <p>The first hint is available when you start a new game. The second hint is available after your first incorrect guess. </p>
        <p>
            If you're new to cryptic clues you should read the <a href="/learn">introduction to cryptic clue guide</a>. 
        </p>
        <p>If you want to receive a new clue each day, you can <a href="/subscribe">subscribe to the daily cryptic clue</a> or click the notification icon on the top left. </p>
        <p>We're still working on some of the features of this game so we'd love to hear what you think. If you love it, hate it or find a problem please let us know: <a href="mailto:crossword@theoutlier.co.za">crossword@theoutlier.co.za</a></p>
        <p class="version"><a href="/changes">Version: {$version}</a></p>&nbsp;
        
       
        
    </div>
</div>
{/if}


<style>
    .statistics { 
        position: fixed;
        top: 0px; 
        left: 0px;
        width: 100%;
        height: 100vh; 
        /* background: #000000d6;   */
        z-index: 1000; 
        text-align: center;
    }
    .statistics-inner { 
        width: 90%; 
        max-width: 600px; 
        padding: 30px;
        background: #fff;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20vh;
        position: relative;
        line-height: 1.3rem;
        font-size: 0.9rem;
        border: solid 1px #c3c3c3;
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
-moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
    }
    .statistics-inner a { 
        color: #BE3131; 
        text-decoration: underline;
    }
    .close-button { 
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        margin: 5px;
    }
    .stats-numbers { 
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr;
    }
    .stats-number { 
        padding: 20px;
        /* border: solid 1px #eee; */
    }
    .big-number{ 
        font-size: 2rem;
        font-weight: 700;
    }
    .label { 
        color: gray;
        margin-top: 10px;
        font-size: 0.9rem;
    }
    table { 
        width: 100%; 
        border-collapse: collapse;
        font-size: 0.8rem;

    }
    th { 
        background: #BE3131;
        color: #fff;
        padding: 5px;
        font-size: 0.9rem;
    }
    td { 
        border: solid 1px #eee;
        padding: 5px;

    }
    .no-padding { 
        padding: 0px !important;
    }
    .result { 
        size: 30px;
    }
    .attempt-blocks { 
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        color: #cecece;
        /* grid-gap: 5px; */
    }
    .attempt-correct { 
        background: rgba(177, 177, 177, 0.425); 
        padding: 5px;
        color: #000;
    }
    .attempt-incorrect { 
        background: rgba(212, 0, 0, 0.44); 
        padding: 5px;
        color: #000;
        
    }
    .block { 
        border: solid 1px #fff;
    }
    .version { 
        font-size: 0.8rem;
    }
</style>

