<script>
  let shareData = {
        twitter: {
            // hashtags: 'outlier',
            // via: 'outlier',
            text: "I just played Cluedle, a daily crossword puzzle from @outlierafrica. Check it out at https://cluedle.theoutlier.co.za/"
        },
        facebook: { 
            quote: "I just played Cluedle, a daily crossword puzzle from @outlierafrica. Check it out at https://cluedle.theoutlier.co.za/", 
            url: 'https://cluedle.theoutlier.co.za/'
        },
        email: {
            subject: "Have you played Cluedle?",
            body: "I just played Cluedle and thought you might enjoy it too. Check it out at https://cluedle.theoutlier.co.za/"
        }
      }
  import { ShareBox } from '@mediahackza/outlier-components'
  import { fly, fade } from 'svelte/transition'
   let showSharing = false
  import belldot from '$assets/bell-dot.svg'
  import { Home, HelpCircle, BarChart3, Mail, Share2 , BellPlus } from 'lucide-svelte';
  import { updateStats } from '$assets/functions.js' 
    let promise = false
    import { dev } from '$app/environment';
    import barchart  from '$assets/barchart.png';
    import help  from '$assets/help.png';
    // import logo  from '$assets/cluedle06.png';
    import logo from '$assets/cluedle2024.png'

    import bell  from '$assets/bell.png';
    import home  from '$assets/home.svg';
    export let showStatistics = false
    export let showHelp = false
    // $: showHelp = showHelp
    $: showStatistics = showStatistics
    import Statistics from '$components/Statistics.svelte'
    import HowTo from '$components/HowTo.svelte'
	import { onMount } from 'svelte';

    function switchStatistics() { 
        showStatistics = !showStatistics
    }
    function switchHelp() { 
      showHelp = !showHelp
    }
    onMount(() => { 
      promise = true
    })
</script>


{#if promise}

  <Statistics showStatistics = {showStatistics}  />
  <HowTo showHelp = {showHelp} />

{/if}
<header>
    <div class="header-inner">
      <div class="menu-left">
        <div class="left-icon">
          <a href="/" class="header-menu"><Home /></a>
          <!-- <a href="/subscribe" class="header-menu"><BellPlus /></a> -->
          <div class="sharing">
            <span on:click={() => showSharing = !showSharing}><Share2 /></span>
            {#if showSharing}
            <div class="sharing-items" transition:fade="{{ duration: 500 }}">
              <ShareBox url="https://theoutlier.co.za/" title="checkout the Outlier website" data={shareData}/>
            </div>
            {/if}
          </div>
          
        </div>
      </div>
      <div class="logo">
        <a href="/"><img src="{logo}" alt="Outlier Crossword Logo"></a>
      </div>
      <div class="menu-right">
        <span on:click={switchHelp} on:keydown={switchHelp}><HelpCircle /></span> &nbsp; 
        <span on:click={switchStatistics} on:keydown={switchStatistics}><BarChart3 /></span>
        <!-- <img src="{help}" alt="Help Icon" on:click={switchHelp}>
        <img src="{barchart}" alt="Statistics Bar Chart" on:click={switchStatistics}> -->
        
      </div>
    </div>
</header>


<style>
     header {
      color: #000;
      border-bottom: solid 1px #dfdfdf;
      padding: 4px 20px;
      font-size: 0.9rem;
      font-weight: 700;
      text-transform: uppercase;
      background: #fff;
      /* background: #0089AA; */

      
    }
    .header-inner { 
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      margin-left: auto;
      margin-right: auto;
      
    }
    
header a { 
    color: #000;
    /* margin-right: 5px; */
}    

:global(.lucide-icon) {
  stroke-width: 2px;
  width: 20px;
  cursor: pointer;
}


    .menu-left {
      text-align: left;
      padding-top: 5px;
      cursor: pointer;
    }
    .menu-right {
      text-align: right;
      padding-top: 5px;
      cursor: pointer;
    }
    .logo {
      font-size: 1.5rem;
      font-weight: 700;
      text-align: center;
    }
    .logo img { 
      margin-left: auto;
      margin-right: auto;
      transform: translate(0px, 0px);
      max-width: 150px;
      
    }

    :global(.menu-left svg) { 
      margin-right: 3px;
    }

  


 




    :global(.menu-left svg), :global(.menu-right svg) { 
      /* width: 200px; */
      margin-right: 10px;
      /* transform: translate(0px, 5px); */
    }


    @media only screen and (max-width: 800px) {
      header {
        font-size: 0.8rem;
      }
      .logo {
        font-size: 1.2rem;
        font-weight: 700;
        text-align: center;
        padding-top: 5px;
      }

      :global(.menu-left svg), :global(.menu-right svg) { 
        margin-right: 10px;
      }
    
     
     
      .logo img {
        max-width: 120px;
        transform: translate(0px,0px);
      }
    }

    
    .sharing { 
      position: relative; 
      display: inline-block;
    }
    .sharing-items  { 
      position: absolute;
      top: 25px;
      left: 0px;
      background: #ffffff;
      border: solid 1px #dfdfdf;
      padding: 10px;
      border-radius: 0px;
      z-index: 1000;
      /* width: 200px; */
    }
    :global(.ssbc-button) { 
      max-width: 20px;
      max-height: 20px;
    }
</style>