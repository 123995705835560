<script>
     const lsname = 'crossword'
    export let showStatistics
    import { fly } from 'svelte/transition';
 

    function closeStatistics() {
        showStatistics = false
        showStatistics = showStatistics
    }


   
  let months = { 
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
  }

  import { ChevronLeft, Check, X } from 'lucide-svelte';
    import { browser } from '$app/environment'
	import { onMount } from 'svelte';


    let statistics = []
 



    function formatDate(date) { 
      let d = date.split('-')
      return `${parseInt(d[2])} ${months[parseInt(d[1])]} ${d[0]}`
    }

onMount(() => { 
  setInterval(() => {
  if (browser) { 
    statistics = JSON.parse(localStorage.getItem(lsname))
    statistics.record.reverse()
    
  }
}, 1000) 
})



</script>

{#if showStatistics}
<div class="statistics" transition:fly="{{ y: 50, duration: 500 }}">
    <div class="statistics-inner">
        <div class="close-button" on:click={closeStatistics}><X /></div>

        <div class="stats-numbers">
            <div class="stats-number">
                
                <div class="big-number">{statistics.state.played}</div>
                <div class="label">Played</div>
            </div>
            <div class="stats-number">
                
                <div class="big-number">{statistics.state.solved}</div>
                <div class="label">Solved</div>
            </div>
            <div class="stats-number">
                
                <div class="big-number">{statistics.state.streak}</div>
                <div class="label">Current Streak</div>
            </div>
        </div>
        
        <table>
            
         
    
            <tr>
              <th class="section-title">Date</th>
              <th class="section-title">Solved</th>
              <th class="section-title">Attempts</th>
            </tr>
    {#each statistics.record as record}
            <tr>
              <td>{formatDate(record.date)}</td>
              <td class="result { record.solved ? 'correct' : 'incorrect' }" >
                {#if record.solved}
                  <Check strokeWidth=4 color=green/>
                {:else}
                  <X strokeWidth=4 color=red/>
                {/if}
              </td>
              <td class="no-padding">
                <!-- {record.attempts} -->
                <div class="attempt-blocks">
                    <div class="block {record.attempts > 0 ? 'attempted' : ''}">1</div>
                    <div class="block {record.attempts > 1 ? 'attempted' : ''}">2</div>
                    <div class="block {record.attempts > 2 ? 'attempted' : ''}">3</div>
                </div>
            </td>
            </tr>
            {/each}
    
          </table>
        
    </div>
</div>
{/if}


<style>
    .statistics { 
        position: fixed;
        top: 0px; 
        left: 0px;
        width: 100%;
        height: 100vh; 
        /* background: #000000d6;   */
        z-index: 1000; 
        text-align: center;
        overflow-y: scroll;
    }
    .statistics-inner { 
        width: 80%; 
        max-width: 600px; 
        padding: 30px;
        background: #fff;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20vh;
        position: relative;
        border: solid 1px #c3c3c3;
        -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
-moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.26);
    }
    .close-button { 
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        margin: 5px;
    }
    .stats-numbers { 
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr;
    }
    .stats-number { 
        padding: 20px;
        /* border: solid 1px #eee; */
    }
    .big-number{ 
        font-size: 2rem;
        font-weight: 700;
    }
    .label { 
        color: gray;
        margin-top: 10px;
        font-size: 0.9rem;
    }
    table { 
        width: 100%; 
        border-collapse: collapse;
        font-size: 0.8rem;

    }
    th { 
        background: #BE3131;
        color: #fff;
        padding: 5px;
        font-size: 0.9rem;
    }
    td { 
        border: solid 1px #eee;
        padding: 5px;

    }
    .no-padding { 
        padding: 0px !important;
    }
    .result { 
        size: 30px;
    }
    .attempt-blocks { 
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        
        padding: 5px;
        /* grid-gap: 5px; */
    }
   

    .block { 
        border: solid 1px #fff;
        padding: 5px;
        background: #eee;
        color: #eee;
    }

    .attempted { 
        background: rgb(170, 170, 170); 
        padding: 5px;
        color: #fff;
        font-weight: 700;
    }
</style>

