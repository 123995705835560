<script>
    import Header from "$components/Header.svelte"
    import "../styles.css"
</script>






<!-- <meta property="fb:app_id" content="319194411438328">
<meta property="fb:admins" content="616167736">
<meta property="fb:admins" content="625796953">
<meta property="fb:admins" content="571759798"> -->

<svelte:head>

</svelte:head>

<div class="main">
    <Header />
    <script>
        let link_indicator = 0;

        if (localStorage.getItem('site-visit') === null) {

            // console.log("setting storage")
            let siteVisit = 1;
            localStorage.setItem('site-visit', siteVisit);
            
        } else {
            // console.log("getting data");
            if (!Boolean(sessionStorage.getItem('site-visit'))) {
                let siteVisit = localStorage.getItem('site-visit');
                siteVisit = +siteVisit + 1;

                localStorage.setItem('site-visit', siteVisit);
            } else {
                // console.log("it has been visited")
            }
        }

        sessionStorage.setItem('site-visit', true);

    </script>
    <slot>
        
    </slot>
</div>